import { bool, envsafe, str, url } from 'envsafe'

export default envsafe(
  {
    NEXT_PUBLIC_HOST: url({
      devDefault: 'http://localhost:3000',
      default: 'https://samansa.com',
      input: process.env.NEXT_PUBLIC_HOST,
    }),
    NEXT_PUBLIC_API_HOST: url({
      devDefault: 'http://localhost:82',
      input: process.env.NEXT_PUBLIC_API_HOST,
    }),
    NEXT_PUBLIC_COGNITO_ENV: str({
      choices: ['dev', 'stg', 'prod'],
      input: process.env.NEXT_PUBLIC_COGNITO_ENV,
    }),
    NEXT_PUBLIC_ENV: str({
      default: 'development',
      input: process.env.NEXT_PUBLIC_ENV,
    }),
    NEXT_PUBLIC_STRIPE_PK: str({
      devDefault:
        'pk_test_51JfhAIGd267zYr8XmvYw5KEZovXTuNzdlGGUE9egFvlKCM4OkjnKRcG84y8tSwCdIyv51eNhoZ9NZf0edvHAHZ0L00HLpgSubE',
      input: process.env.NEXT_PUBLIC_STRIPE_PK,
    }),
    NEXT_PUBLIC_PLAYER_PROXY_LAMBDA: url({
      devDefault: 'https://i6b8o8vwni.execute-api.ap-northeast-1.amazonaws.com/develop/proxy',
      input: process.env.NEXT_PUBLIC_PLAYER_PROXY_LAMBDA,
    }),
    NEXT_PUBLIC_GTM_ID: str({
      default: 'GTM-TMHZ2VL',
      input: process.env.NEXT_PUBLIC_GTM_ID,
    }),
    NEXT_PUBLIC_GTM_ENABLED: bool({
      default: false,
      input: process.env.NEXT_PUBLIC_GTM_ENABLED,
    }),
    NEXT_PUBLIC_CLOUDFRONT_URL: str({
      default: 'https://d8cip8330xdjp.cloudfront.net',
      input: process.env.NEXT_PUBLIC_CLOUDFRONT_URL,
    }),
    APP_LINK: str({
      devDefault: 'smns-dev://dev.samansa.com',
      default: 'smns://samansa.com',
    }),
    APP_STORE_LINK_IOS: str({
      default: 'https://apps.apple.com/jp/app/app-name/id1600425602',
    }),
    APP_STORE_LINK_ANDROID: str({
      default: 'https://play.google.com/store/apps/details?id=com.samansa',
    }),
  },
  { strict: true },
)
