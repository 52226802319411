'use client'

import { Auth } from 'aws-amplify'
import axios, { isAxiosError } from 'axios'
import { Session } from 'next-auth'
import { signOut, useSession } from 'next-auth/react'
import { useMemo } from 'react'
import browserEnv from '../env/browserEnv'

const createApi = (snsSession: Session | null) => {
  const api = axios.create({
    baseURL: browserEnv.NEXT_PUBLIC_API_HOST,
    timeout: 60000,
  })

  api.interceptors.request.use(async (config) => {
    const headers = config.headers ?? {}

    let token: string | undefined
    if (snsSession) {
      token = snsSession.access_token
    } else {
      try {
        const session = await Auth.currentSession()
        token = session.getAccessToken().getJwtToken()
      } catch (e) {}
    }

    if (token !== undefined) {
      headers['Authorization'] = `Bearer ${token}`
    }

    config.headers = headers
    return config
  })
  api.interceptors.response.use(
    (res) => {
      return res
    },
    async (error) => {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          case 401:
            signOut({ callbackUrl: '/login' })
            return { data: null }
          case 503:
            window.location.href = '/maintenance'
            break
          default:
            break
        }
      }
      switch (error.code) {
        case 'ECONNABORTED':
          const errorPath = window.location.pathname
          window.location.href = `/timeout?error-path=${errorPath}`
          break
      }
      console.error(error)
      return Promise.reject(error)
    },
  )

  return api
}

export const useAxios = () => {
  const { data: snsSession } = useSession()

  return useMemo(() => createApi(snsSession), [snsSession])
}
